import React, { useState } from 'react';
import SEO from '../components/SEO';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer.jsx';

import {
  Col, Row, Container,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: '../../about/Stay True to Yourself_home_slider_web.jpg',
    href: 'https://wantinghsieh.com/stay-true-to-yourself/',
    altText: 'Stay True to Yourself',
    title: 'Stay True to Yourself',
    subtitle: 'Interactive Sound & Light Installation',
  },
  {
    src: '../../about/keep-walking-home-slider_web.jpg',
    href: 'https://wantinghsieh.com/keep-walking/',
    altText: 'Keep Walking',
    title: 'Keep Walking',
    subtitle: ' Interactive Light Installation',
  },
  {
    src: '../../about/Moving Instrumentbot_1_youtube.jpg',
    href: 'https://wantinghsieh.com/moving-instrumentbot/',
    altText: 'Moving Instrumentbot',
    title: 'Moving Instrumentbot',
    subtitle: 'Interactive Kinetic WiFi Robot',
  },
  {
    src: '../../about/Smart Hat_banner_web.jpg',
    href: 'https://wantinghsieh.com/hat-mask/',
    altText: 'Smart Hat & Communicable Mask',
    title: 'Smart Hat & Communicable Mask',
    subtitle: 'Interactive Wearable Devices',
  },
];

const About = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <a href={item.href} target="_blank">  <img alt='' src={item.src} alt={item.altText} /></a>
        <CarouselCaption captionHeader={item.title} captionText={item.subtitle} />
      </CarouselItem>
    );
  });

  return (
    <div>
      <SEO title="About" />
      <Header />
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>

      <Container className='my-5'>
        <Row>
          <Col lg='3' xs='4' className='text-center'>
            <img alt='' src='../../about/avatar.jpg' className='rounded-circle avatar' />
            <h5 className='my-2'>謝宛庭 | WAN-TING HSIEH</h5>
            <div>
              <Button className='mx-1 my-1' outline color="primary" href='https://wantinghsieh.com'>
                <i className="fa fa-globe mr-2" aria-hidden="true"></i>Portfolio
              </Button>
              <Button className='mx-1 my-1' outline color="primary" href='http://www.linkedin.com/in/wendy-hsieh'>
                <i className="fa fa-linkedin mr-2" aria-hidden="true"></i>LinkedIn
              </Button>
              <Button className='mx-1 my-1' outline color="primary" href='https://github.com/cv47522'>
                <i className="fa fa-github mr-2" aria-hidden="true"></i>GitHub
              </Button>
              <Button className='mx-1 my-1' outline color="primary" href='https://gitlab.com/cv47522'>
                <i className="fa fa-gitlab mr-2" aria-hidden="true"></i>GitLab
              </Button>
            </div>
          </Col>
          <Col lg='9' xs='8'>
            <p>Hi, I am Wan-Ting Hsieh and it is easier to remember my name as Wendy Hsieh. I come from Taiwan.</p>
            <p>Before studying at Aalto University, I had been working as a full stack web developer in Taiwan for 1 year.</p>
            <p>
              My undergraduate background is a combination of 4-year bachelor's program in New Media Art and 1-year exchange program in Electrical Engineering and Computer Science (EECS).
            </p>
            <p>
              But before studying in college, I had always been interested in drawing and done lots of paintings with a wide range of materials such as watercolor, crayon, acrylic, sketch, comics and pastel since elementary school.
            </p>
            <p>
              I have always liked to do handicrafts which require calculation and aesthetic design, which is why I join Fab Academy to explore more machine-based fabrication methods.
            </p>
            <p>
              The slides show some of my interactive artworks done in both degrees.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
